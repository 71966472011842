// import '../../shared/nav/collapsing_nav'  // removed in v3
import '../shared/phone'
import '../shared/package_gallery/image_gallery'
import '../shared/buyers_page_personalizer'
import './stripe_styling.js'

import PriceFilter from '../shared/price_filter'
import AddToCartForms from '../shared/add_to_cart_forms'
import HotelIndex from '../shared/add_to_cart_forms/h4h_hotels/hotel_index'
import { store } from'../default/store'
import CartNav from '../shared/nav/nav_cart'
import Checkout from '../shared/checkout'
import DepositRedemptions from '../shared/deposit_redemptions'
import '../default/carousel_bs5'   // added in v3
import '../shared/hide_map'
// import '../shared/seating_chart_filter'
import './seating_chart_filter'

import 'bootstrap/dist/js/bootstrap.esm.js'  // added in v3
import '../shared/nav/bootstrap_nav.js'  // added in v3

import '../shared/sentry'
import '../shared/package_comparisons'
// import '../shared/disable_reset' // added in v3
import '../f1/slide_elems' // added in v3
import './stripe_styling'

import VueLoader from '../default/vue-loader'
import LightBox from '../shared/package_gallery/lightbox_gallery.vue'
import ImageGallery from '../shared/package_gallery/package_gallery.vue'
import OrderListBootstrap from '../default/orders/stripe_orders/order_list_bootstrap'
import AddToCart from '../full-gpe/cart/add-to-cart'

let price_filters = document.querySelectorAll('#seating_chart_price')

document.addEventListener('turbo:load', () => {
  AddToCartForms.loadBottomForm()
  CartNav.addCartToNavbar()
  DepositRedemptions.findCustomerDeposits()
  Checkout.loadWithAddOn()
  AddToCart.AddToCart()
  VueLoader.loadVueComponent(ImageGallery, document.getElementById('image_gallery'))
  if (document.getElementById('lightbox_gallery')){
    document.getElementById('lightbox_gallery').classList.add('hide')
  }
  VueLoader.loadVueComponent(LightBox, document.getElementById('lightbox_gallery'))
  VueLoader.loadVueComponent(HotelIndex, document.getElementById('hotelIndex'), store)
  VueLoader.loadVueComponent(OrderListBootstrap, document.getElementById('customer_order_list'))
  VueLoader.loadVueComponent(PriceFilter, document.getElementById('seating_chart_price'), store)

  //ensures mobile price filters load
  price_filters.forEach(elem => {
    if (elem.hasChildNodes() == false) {
      VueLoader.loadVueComponent(PriceFilter, elem, store)
    }
  })
})
